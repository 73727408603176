import React from "react";
//import { useSelector } from "react-redux";
import { ReactComponent as CallIcon } from '../assets/images/call-icon.svg';
import { ReactComponent as SportexLogo } from '../assets/images/sportex-logo.svg';
//import Loader from './Loader'

const Footer = () => {

    //const isLoading = useSelector(state => state.search.status)

    return(
        <>
            <div className="footer d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-end">
                    <div className="me-1"><CallIcon /></div>
                    <div>Questions? <span>CALL US! 1.800.486.8677</span></div>
                </div>
                <div className="poweredby">
                    Powered By <span><SportexLogo /></span>
                </div>
            </div>
            {/* {
                isLoading === 'loading' ? <Loader /> : null
            } */}
            
        </>
    )
}

export default Footer