import React, { useState } from "react";
import { useFormik } from 'formik';
import { Form, Button } from "react-bootstrap";
import { ReactComponent as SearchIcon } from '../assets/images/search-icon.svg'
import { useDispatch } from "react-redux";
import { searchResult } from "../features/searchSlice";
import { useNavigate } from "react-router-dom";
import Loader from './Loader'

const validate = values => {
    const errors = {};
    if (!values.search) {
        errors.search = 'Search a term like LSA';
      } 
    //   else {
    //     errors.search = 'Invalid Access Code';
    //   }
      return errors; 
  };

const ShareSearchBar = (props) => {

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const formik = useFormik({
        initialValues: {
            search: '',
            startidx : 0,
            endidx : 25,
        },
        validate,
        onSubmit: (values, resetForm, initialValues) => {
            //alert(JSON.stringify(values, null, 2));
            //console.log(values)
            setLoading(true)
            localStorage.setItem("searchText", values.search)
            dispatch(searchResult(values))
            .then((res)=>{
                setLoading(false)
                formik.resetForm()
                navigate('/sharelist')
            })
        },
      });
      //console.log("search bar", loading)
    return(
        <>
            <div className={props.className + ' searchbox'}>
                <form onSubmit={formik.handleSubmit}>
                    <div className="input-group">
                        <span className="input-group-text" id="basic-addon1"><SearchIcon /></span>
                        <input type="text" className={`form-control`} id="search" name="search" auto="true" value={formik.values.search} onChange={formik.handleChange} disabled={loading === true ? true : false} placeholder="SEARCH" />
                        <Form.Text className="text-danger text-start d-block mt-0 w-100"> {formik.errors.search && formik.errors.search} </Form.Text>
                    </div>
                    <div className="">
                        <Button variant="dark" size="lg" className="w-100" type="submit" hidden> Search</Button>
                    </div>
                </form>
            </div>
            {
                loading === true ? <Loader /> : null
            }
            
        </>
    )
}

export default ShareSearchBar