import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    status: 'idle',
    searchlist: [],
    searchText:'',
    error: '',
    startidx: 0,
    endidx: 25,
    morebtn: true
}

export const searchResult = createAsyncThunk(
    'search/searchResult',
    async (values) => {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/products`, values)
        //console.log(res.data)
        return res.data.data
    }
)

export const loadMoreRecords = createAsyncThunk(
    'search/loadMoreRecords',
    async (values) => {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/products`, values)
        //console.log(res.data)
        return res.data.data
    }
)

export const searchSlice = createSlice({
    name:'search',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(searchResult.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(searchResult.fulfilled, (state, action) => {
            state.status = "true"
            state.error = ''
            state.searchlist = action.payload
            state.startidx = 25
            state.endidx = 50
            state.morebtn = true
            if(action.payload.length < 25) {
                state.morebtn = false
            }
            if(action.payload.length === 0) {
                toast.info("No results found", { position:"bottom-center"})
            }
        })
        .addCase(searchResult.rejected, (state, action) => {
            state.status = "false"
            state.searchlist = []
            state.error = action.error.message
        })
        .addCase(loadMoreRecords.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(loadMoreRecords.fulfilled, (state, action) => {
            state.status = "true"
            state.error = ''
            state.startidx += 25
            state.endidx += 25
            state.morebtn = true
            state.searchlist = [...state.searchlist, ...action.payload];
            if (action.payload.length < 25) {
                state.morebtn = false
            }
        })
        .addCase(loadMoreRecords.rejected, (state, action) => {
            state.status = "false"
            state.searchlist = []
            state.error = action.error.message
        })
    }
})

export const searchresultlist = (state) => state.search.searchlist
export default searchSlice.reducer