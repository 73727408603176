import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { isLogin } from "../features/accesscodeSlice";

const Protected = (props) => {
    const {Component} = props
    const isLoggedIn = useSelector(isLogin)
    
    // const navigate = useNavigate()
    
    // useEffect(() => {
    //     if(!isLoggedIn) {
    //         console.log("protected", isLoggedIn)
    //         navigate('/')
    //     }
    // }, [navigate, isLoggedIn])
    // return(
    //     <>  
    //         <Component />
    //     </>
    // )

    if(!isLoggedIn) {
        return <Navigate to="/" />
    }

    return <Component />
    
}

export default Protected