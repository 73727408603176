import React from "react";
import { Watch } from "react-loader-spinner";

const loader = () => {
    return(
        <>
            <div className="commonloader">
            <Watch
  height="80"
  width="80"
  radius="48"
  color="#ffffff"
  ariaLabel="watch-loading"
  wrapperStyle={{}}
  wrapperClassName=""
  visible={true}
/>
            </div>
        </>
    )
}

export default loader