import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    status : 'idle',
    userData : '',
    error: ''
}

export const checkLogin = createAsyncThunk(
    'signin/checklogin',
    async (data) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/register`, data)
            //console.log("slice", response.data)
            return response.data
        }
        catch (error) {
            return error
        }
    }
)

export const signinSlice = createSlice({
    name: 'signin',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(checkLogin.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(checkLogin.fulfilled, (state, action) => {
            state.status = 'true'
            state.userData = action.payload
            state.error = ''
        })
        .addCase(checkLogin.rejected, (state, action) => {
            state.status = 'false'
            state.userData = ''
            state.error = action.error.message
        })
    }
    
})
export const signinemail = state => state.signin.userData.data.email_id
export default signinSlice.reducer