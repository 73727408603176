import { configureStore, combineReducers } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import storageSession from 'redux-persist/lib/storage/session'
import accesscodeReducer  from '../features/accesscodeSlice';
import signinReducer from '../features/singninSlice';
import searchReducer from '../features/searchSlice';
import cartReducer, { getTotals } from '../features/cartSlice';
import checkoutReducer from '../features/checkoutSlice';
import addContentReducer from '../features/addContentSlice';


const persistConfig = {
  key: 'sportex-designapp',
  version: 1,
  storage,
  //blacklist: ['search']
}

const rootReducer = combineReducers({ 
  counter: counterReducer,
  signin: signinReducer,
  accesscode: accesscodeReducer,
  search: searchReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
  addcontent: addContentReducer
})



const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});

store.dispatch(getTotals())

export const persistor = persistStore(store)
// console.log('intial state', store.getState())
