import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    status: 'idle',
    userCode: '',
    isLogin : false,
    error: ''
}

export const checkCode = createAsyncThunk(
    'name/checkCode',
    async (data) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/login`, data)
            return response.data
        }
        catch (error) {
            return error
        }
    }
)

export const accesscodeSlice = createSlice({
    name:'accesscode',
    initialState,
    reducers: {
        logout: state => {
            state.status = 'idle'
            state.userCode = ''
            state.isLogin = false
            localStorage.removeItem("isLogin")
            localStorage.removeItem("persist:sportex-designapp")
          }
    },
    extraReducers: (builder) => {
        builder
        .addCase(checkCode.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(checkCode.fulfilled, (state, action) => {
            state.status = action.payload.status
            state.userCode = action.payload
            if(action.payload.status === 'success'){
                state.isLogin = true
                localStorage.setItem("isLogin", true)
            }
            else {
                state.isLogin = false
                localStorage.setItem("isLogin", false)
            }
        })
        .addCase(checkCode.rejected, (state, action) => {
            state.status = 'false'
            state.userCode = ''
            state.error = action.error.message
        })        
    }
})

export const { logout } = accesscodeSlice.actions
export const isLogin = (state) => state.accesscode.isLogin
export default accesscodeSlice.reducer

