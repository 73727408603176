import React from 'react';
import './assets/scss/style.scss';
import Layout from './component/Layout';

const App = () => {
  return(
    <>
      <div className='wrapper'>
        <Layout />
      </div>      
    </>
  )
}

export default App;
