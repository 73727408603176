import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    status : 'idle',
    contentAuthCode : '',
    isAuth : false,
    listcontent: '',
    error: ''
}

export const checkaddcontentauth = createAsyncThunk(
    'addcontent/checkaddcontentauth',
    async (data) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth-login`, data)
            //console.log("slice", response.data)
            return response.data
        }
        catch (error) {
            return error
        }
    }
)

export const addData = createAsyncThunk(
    'addcontent/addData',
    async (data) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/add-content`, data)
            //console.log("slice", response.data)
            return response.data
        }
        catch (error) {
            return error
        }
    }
)

export const getlistcontent = createAsyncThunk(
    'addcontent/getlistcontent',
    async (data) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/content`, data)
            //console.log("slice", response.data)
            return response.data
        }
        catch (error) {
            return error
        }
    }
)

export const addContentSlice = createSlice({
    name: 'addcontent',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(checkaddcontentauth.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(checkaddcontentauth.fulfilled, (state, action) => {
            state.status = 'true'
            state.contentAuthCode = action.payload
            if(action.payload.status === 'success') {
                state.isAuth = true;
            }
            else {
                state.isAuth = false;
            }            
            state.error = ''
        })
        .addCase(checkaddcontentauth.rejected, (state, action) => {
            state.status = 'false'
            state.contentAuthCode = ''
            state.isAuth = false;
            state.error = action.error.message
        })
        .addCase(addData.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(addData.fulfilled, (state, action) => {
            state.status = 'true'
            state.error = ''
            toast.success("Content updated successfully", { position:"bottom-left"})
        })
        .addCase(addData.rejected, (state, action) => {
            state.status = 'false'
            state.error = action.error.message
            toast.error("Something went wrong.", { position:"bottom-left"})
        })
        .addCase(getlistcontent.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(getlistcontent.fulfilled, (state, action) => {
            state.status = 'true'
            state.listcontent = action.payload
            state.error = ''
        })
        .addCase(getlistcontent.rejected, (state, action) => {
            state.status = 'false'
            state.listcontent = ''
            state.error = action.error.message
        })
    }
    
})
export const isAuth = (state) => state.addcontent.isAuth
export default addContentSlice.reducer