import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
    status: 'idle',
    checkData: '',
    error:''
}

export const sendMail = createAsyncThunk(
    'checkout/sendMail',
    async (data) => {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/checkout`, data)
        console.log("checkout slice", response)
        return response
    }
)

export const checkoutSlice = createSlice({
    name:'checkout',
    initialState,
    reducers:{

    },
    extraReducers: (builder) => {
        builder
        .addCase(sendMail.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(sendMail.fulfilled, (state, action) => {
            state.status = 'true'
            state.checkData = action.payload
            toast.success("Mail sent successfully", { position:"bottom-left"})
        })
        .addCase(sendMail.rejected, (state, action) => {
            state.status = 'false'
            state.checkData = ''
            state.error = action.error.message
        })
    }
    
})

export default checkoutSlice.reducer