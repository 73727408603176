import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
    cartItems : localStorage.getItem("cartItems") ? JSON.parse(localStorage.getItem("cartItems")) : [],
    cartTotalQuantity : 0
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers:{
        addToCart: (state, action) => {
            const itemIndex = state.cartItems.findIndex(
                (item) => item.id === action.payload.id
            )
            if(itemIndex >= 0) {
                //state.cartItems[itemIndex].cartQty += 1
                toast.info("product already added to cart", { position:"bottom-left"})
            }
            else {
                const tempProduct = { ...action.payload, cartQty : 1}
                state.cartItems.push(tempProduct)
                toast.success("product added to cart", { position:"bottom-left"})
            }
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
        },
        removeFromCart : (state, action) => {
            const nextCartItems = state.cartItems.filter(
                (cartItem) => cartItem.id !== action.payload.id
            );
            state.cartItems = nextCartItems;
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
        },
        clearCart : (state, action) => {
            state.cartItems = [];
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
        },
        getTotals: (state, action) => {
            let {quantity} = state.cartItems.reduce(
                (cartTotal, cartItem) => {
                    const {cartQty} = cartItem;
                    
                    cartTotal.quantity += cartQty;
                    return cartTotal
                }, {
                    quantity : 0
                }
            );
            state.cartTotalQuantity = quantity;
        }
    }
})

export const {addToCart, removeFromCart, getTotals, clearCart} = cartSlice.actions
export const userDetails = (state) => state.signin.userData.data
export default cartSlice.reducer