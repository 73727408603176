import React, {useEffect, useState} from "react";
import { Button, Offcanvas, Form } from "react-bootstrap";
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';
import { ReactComponent as TrashIcon } from '../../assets/images/trash-icon.svg';
import ImageBig from '../../assets/images/imagebig.png'
import placeholder from '../../assets/images/placeholder.png' 

import { useFormik } from 'formik'
import { ReactComponent as SendIcon } from '../../assets/images/send-icon.svg';
import { useDispatch, useSelector } from "react-redux";
import { clearCart, getTotals, removeFromCart, userDetails } from "../../features/cartSlice";
import { sendMail } from "../../features/checkoutSlice";

const validate = values => {
    const errors = {};
    if (!values.fullname) {
        errors.fullname = 'Full Name is Required';
    }
    if (!values.contact_email_id) {
        errors.contact_email_id = 'Email Address is Required';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.contact_email_id)) {
        errors.contact_email_id = 'Invalid email address';
    }
    if (values.isChecked && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.optional_email_id)) {
        errors.optional_email_id = 'Invalid email address';
    }  
    return errors; 
  };
const CartModal = (props) => {
    const [loading, setLoading] = useState(false)
    const [sendData, setSendData] = useState([])

    const cartItemList = useSelector(state => state.cart.cartItems)
    const cartQty = useSelector(state => state.cart.cartTotalQuantity)

    //const useremail = useSelector(state => state.signin.userData.data.email_id)
    const userdata = useSelector(userDetails)
    const useremail = userdata && userdata.email_id
    const useremailsend = useremail ? useremail : localStorage.getItem('loginemail')
    //console.log("useremailsend", useremailsend)

    const dispatch = useDispatch()

    const removeItemHandle = (cart) => {
        dispatch(removeFromCart(cart))
        dispatch(getTotals())
    }

    const formik = useFormik({
        initialValues: {
            fullname: '',
            contact_email_id:'',
            //phone_no:'',
            message:'',
            company:'',
            isChecked:false,
            optional_email_id:'',
            
        },
        validate,
        onSubmit: (values, resetForm, initialValues) => {
            //alert(JSON.stringify(values, null, 2));
            console.log(values)
            setLoading(true)
            dispatch(sendMail(sendData))
            .then(()=> {
                formik.resetForm()
                setLoading(false)
                dispatch(clearCart())
                dispatch(getTotals())
                props.handleCloseCart()
                }
            );
        },
      });

      useEffect(()=>{
        setSendData({...formik.values, cart_items: cartItemList, email_id: useremailsend} )
      }, [formik.values])
      //console.log("cartItemList", cartItemList)
    return (
        <>
            <Offcanvas show={props.show} onHide={props.handleClose} placement="end" className="cartmodal" backdropClassName="newbackdrop">
                <Offcanvas.Header>
                    <Offcanvas.Title>DESIGN LIST</Offcanvas.Title>
                    <div onClick={props.handleCloseCart} className="closemodal"><CloseIcon /></div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="itemcount">({cartQty}) Items</div>
                    <div className="cartitem">
                        {
                            cartItemList?.map((cartItem) => {
                                return(
                                    <div className="cartitembox" key={cartItem.id}>
                                        <div className="imgbox"><img src={cartItem.custrecord_childdesign_image_url ? cartItem.custrecord_childdesign_image_url : placeholder} alt="" className="img-fluid" /></div>
                                        <div className="desc">
                                            <small>{cartItem.custrecord_childdesign_design}</small>
                                            <h4>{cartItem.custrecord_childdesign_designname}</h4>
                                            <small>{cartItem.custrecord_childdesign_designname}</small>
                                        </div>
                                        <div className="action ms-auto">
                                            <Button className="bg-white p-2 border-0" onClick={() => removeItemHandle(cartItem)}><TrashIcon /></Button>
                                        </div>
                                    </div>            
                                )
                            })
                        }
                        {/* <div className="cartitembox">
                            <div className="imgbox"><img src={ImageBig} alt="" className="img-fluid" /></div>
                            <div className="desc">
                                <small>DES19779-001</small>
                                <h4>TIC - Crush Points - Banner</h4>
                                <small>TIC - Crush Points - Banner</small>
                            </div>
                            <div className="action ms-auto">
                                <Button className="bg-white p-2 border-0"><TrashIcon /></Button>
                            </div>
                        </div>
                        <div className="cartitembox">
                            <div className="imgbox"><img src={ImageBig} alt="" className="img-fluid" /></div>
                            <div className="desc">
                                <small>DES19779-001</small>
                                <h4>TIC - Crush Points - Banner</h4>
                                <small>TIC - Crush Points - Banner</small>
                            </div>
                            <div className="action ms-auto">
                                <Button className="bg-white p-2 border-0"><TrashIcon /></Button>
                            </div>
                        </div> */}
                    </div>
                    {/* cartitem end */}
                    <div className="contactinfo">
                        <h2>Contact Info</h2>
                        <form onSubmit={formik.handleSubmit}>
                        <div className="mb-2">
                            <input type="text" className={`form-control`} id="fullname" name="fullname" value={formik.values.fullname} onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={loading === true ? true : false} placeholder="Recipents Name" />
                            <Form.Text className="text-danger text-start d-block mt-2 w-100"> 
                                {formik.touched.fullname && formik.errors.fullname ? (
                                    <div>{formik.errors.fullname}</div>
                                ) : null}
                            </Form.Text>
                        </div>
                        <div className="mb-2">
                            <input type="text" className={`form-control`} id="contact_email_id" name="contact_email_id" value={formik.values.contact_email_id} onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={loading === true ? true : false} placeholder="Recipents Email Address" />
                            <Form.Text className="text-danger text-start d-block mt-2 w-100"> 
                                {formik.touched.contact_email_id && formik.errors.contact_email_id ? (
                                    <div>{formik.errors.contact_email_id}</div>
                                ) : null}
                            </Form.Text>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 mb-2">
                                <input type="text" className={`form-control`} id="company" name="company" value={formik.values.company} onChange={formik.handleChange} disabled={loading === true ? true : false} placeholder="Company" />
                            </div>
                            {/* <div className="col-sm-6 mb-2">
                                <input type="text" className={`form-control`} id="phone_no" name="phone_no" value={formik.values.phone_no} onChange={formik.handleChange} disabled={loading === true ? true : false} placeholder="Phone Number" />
                            </div> */}
                        </div>
                        <div className="mb-4">
                            <textarea className="form-control" id="message" name="message" rows="3"  value={formik.values.message} onChange={formik.handleChange} placeholder="Special Instruction or Message"></textarea>
                        </div>
                        <div className="mb-4">
                            <div className="customcheckbox"><label><input type="checkbox" name="isChecked" value={formik.values.isChecked} checked={formik.values.isChecked} onChange={formik.handleChange} /> <span>ADD AN EMAIL ADDRESS</span></label></div>
                        </div>
                        {
                            !formik.values.isChecked ? null : (
                                <div className="mb-4">
                                    <input type="text" className={`form-control`} id="optional_email_id" name="optional_email_id" value={formik.values.optional_email_id} onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={loading === true ? true : false} placeholder="Email Address" />
                                    <Form.Text className="text-danger text-start d-block mt-2 w-100"> 
                                        {formik.touched.optional_email_id && formik.errors.optional_email_id ? (
                                            <div>{formik.errors.optional_email_id}</div>
                                        ) : null}
                                    </Form.Text>
                                </div>
                            )
                        }
                        <div className="">
                        <Button variant="dark" size="lg" className="w-100" type="submit" disabled={ cartQty > 0 ? (loading === true ? true : false) : true}> {loading === true ? "Loading..." : "Send Design List"} <SendIcon className="sendicon ms-2 mt-n1" /></Button>
                        </div>
                    </form>
                    </div>
                    {/* contact info end */}
                    
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
export default CartModal